// libraries
window.$ = require('jquery');
window.jQuery = window.$; //boostrap looks for this
require('jquery-ui/ui/widget');
require('jquery-ui/ui/data');
require('jquery-ui/ui/scroll-parent');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/sortable');
window.angular = require('angular');
window.moment = require('moment'); // todo: just include some locales?

window.agGrid = require('ag-grid-enterprise/dist/ag-grid-enterprise.min.js');
window.tv4 = require('tv4'); // used by angular-schema-form?
window.d3 = require('d3');
require('bootstrap5');
// $.fn.tooltip.Constructor.Default.whiteList.table = [];
// $.fn.tooltip.Constructor.Default.whiteList.tr = [];
// $.fn.tooltip.Constructor.Default.whiteList.td = [];
// $.fn.tooltip.Constructor.Default.whiteList.tbody = [];
// $.fn.tooltip.Constructor.Default.whiteList.thead = []
require('@fortawesome/fontawesome-free');

window.Selectize = require('@selectize/selectize');


// angular modules
require('ngprogress');
require('angular-animate');
require('ngclipboard');
require('ui-select');
require('ui-select/dist/select.css');
require('angular-sanitize');
require('angular-ui-router');
require('angular-ui-bootstrap');
require('angular-ui-sortable');
require('angular-file-upload');
require('angular-growl-v2');
require('angular-growl-v2/build/angular-growl.css');
require('angular-mousetrap-service/release/angular-mousetrap-service.min.js');
require('angular-utils-pagination');
require('angular-schema-form');
require('angular-schema-form-material/material-decorator.js');
require('angular-material');
require('angular-material/angular-material.css');
require('angular-messages');
require('angular-route');
require('angular-filesize-filter');

require('angular-tree-control/context-menu');
require('angular-tree-control/angular-tree-control.js');
require('angular-tree-control/css/tree-control.css');
require('angular-tree-control/css/tree-control-attribute.css');
require('angular-bootstrap-contextmenu');

require('angular-ordinal');
require('@xsites/angular-selectize2');

console.log('vendor.js loaded');